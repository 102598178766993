'use strict';

/**
 * ------------------------------------------------------------------------
 * Namespace our stuff
 * ------------------------------------------------------------------------
 */

// NOTE: JQuery is hot linked in the main site template and is global

const _ = require('lodash');
global. _ = _;

let conf = require('./app.conf.json');

let api = require('./newsome-api.js').NewsomeAPI;

global.env = conf.env;

global.api            = {};
global.api.NewsomeAPI = require('./newsome-api.js').NewsomeAPI;
global.api.conf       = conf;
global.api.NewsomeAPI.init(conf);

var fbq = global.fbq; // facebook pixel

$(document).ready(function()
{
	/* ====================================================================== *
	*                          LOAD CONFIG
	* ========================================================================*/

	var confXHR = $.getJSON('/js/bundle/app.conf.json');

	confXHR.fail(function()
	{
		console.log('ERROR LOADING APP CONFIG');
	});

	confXHR.done(function(data)
	{
		console.log( 'env: ' + data.env );

		// Set api values from data
		if(data.api.site) api.setSite(data.api.site);
		if(data.api.captchaGenUrl) api.setCaptchaGenURL(data.api.captchaGenUrl);
		if(data.api.formPostUrl) api.setFormPostURL(data.api.formPostUrl);

		console.log('site: ' + api.getSite());

		// Set Contact form CAPTCHA image
		api.newCaptchaImage( $('#contactCaptcha'), $('#contactCaptchaFile') );
	});



	/* ====================================================================== *
	*                            CONTACT FORM
	* ========================================================================*/

	// SUBMIT
	$('#contact-form').submit(function(e)
	{
		// Stops the default post action and prevents the page
		// from reloading after submitting
		e.preventDefault();
		e.stopImmediatePropagation();

		// Give the user something to look at
		$(this).find('[data-form-state]').hide();
		$(this).find('[data-form-state=load]').fadeIn(1200);

		// Convert form fields to JSON
		let contactFormJSON = api.JSONform( $('#contact-form') );
		//console.log(contactFormJSON);

		// CLIENT-SIDE ERROR HANDLING
		// Error check non-standard fields that the api does not handle here.
		// contactFormJSON.comments
		
		// Post the data and handle response
		api.formPost(contactFormJSON)
			.done(function(res)
			{
				$('[data-form-state]').hide();               // Hide all form buttons
				$('[data-form-state=success]').fadeIn(1200); // display success button

				$('#formResponse').removeClass('text-danger');
				$('#formResponse').addClass('text-success');
				$('#formResponse').text(res.message);

				$('#contact-form').trigger('reset');

				// Send Event to FB
				if(conf.env == 'production') fbq('track', 'Lead');

				setTimeout(function() {resetForm();}, 5000);
			})
			.fail(function(err)
			{
				$('[data-form-state]').hide();            // Hide all form buttons
				$('[data-form-state=fail]').fadeIn(1200); // display failure button

				$('#formResponse').addClass('text-danger');
				$('#formResponse').text(err.message);
				
				api.newCaptchaImage( $('#contactCaptcha'), $('#contactCaptchaFile') );

				$('#captcha-input').val('');
				
				setTimeout(function() {resetForm();}, 3000);
			});

		// Reset contact form
		function resetForm()
		{
			$('[data-form-state]').hide(); // Hide all form buttons
			$('[data-form-state=ready]').show().fadeIn(400); // display submit
		}

		return false;

	// end #contact-form
	});


	/* ====================================================================== *
	*                           NEW CAPTCHA
	* ========================================================================*/

	// Selectors to load a new captcha image

	// Contact Form
	$('.new-contact-captcha').click(function()
	{
		api.newCaptchaImage( $('#contactCaptcha'), $('#contactCaptchaFile') );
	});


	/* ====================================================================== *
	*                           SMOOTH SCROLL
	* ========================================================================*/

	// Selectors that will scroll to various places on a page

	// Scroll to Top
	$('.scroll-to-top').click(function()
	{
		$('html, body').animate({
			scrollTop: 0
		}, 2000);
	});

	// Scroll to Footer Contact Form
	$('.scroll-to-contact-form').click(function()
	{
		$('html, body').animate({
			scrollTop: $('#contact-form').offset().top
		}, 2000);
	});



	/* ====================================================================== *
	*                           SITE MODAL
	* ========================================================================*/

	// Site Modal Event listener / selector
	$('.open-in-modal').on('click',function()
	{
		// Add a class to the opening element so we can
		// reference more attributes from the modal.
		// Class should be removed by the modal.
		$(this).addClass('modal-opener');
		
		//- - - - - - - - - - - - 
		// Set Modal Template
		var templateName = $(this).data('modal-name');
		var templateUrl = '/templates/modal_' + templateName + '.html';
		//console.log(templateUrl);

		if(!templateName)
		{
			console.log('Missing modal template name. Set data-modal-name attribute.');
			return;
		}
		
		//- - - - - - - - - - - - 
		// Open Modal
		$('#modal').load(templateUrl, function(){
			$('.modal').val( myBookId );
			// Show Modal
			$('#modal').modal({show:true});
		});
	});

// end document.ready
});