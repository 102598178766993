'use strict';

/*
* Newsome API endpoint functions
* Last updated 2019-02-13
*/

const _ = require('lodash');

var NewsomeAPI = (function ()
{
	var _env = '';

	/**
	 * ------------------------------------------------------------------------
	 * INIT
	 * Sets up this module from the site configuration file.
	 * 
	 * oListings is optional and intended to be set server side.
	 * Use loadListings() client-side.
	 * 
	 * @param {object} app jQuery
	 * @param {object} site options
	 * @param {object} optional array of listings
	 * ------------------------------------------------------------------------
	 */

	//var init = function(config, oListings=null)

	var init = function(config)
	{
		_env                = config.env;
		//_listings         = oListings;
		//_mediaBase        = config.app.mediaBase;
		//_listingsFolder   = config.app.listingsFolder;
		//_listingsDataFile = '/js/data/' + config.app.listingsDataFile;
	};



	/* SiteName
	* Site name is used as a label by the API in various places, and when saving
	* data etc. Default is the domain name. Might set this client-side using
	* setter below.
	* --------------------------------------------------------------------------- */
	var site = 'not-set';

	function setSite(strNewSite){
		site = strNewSite;
	}

	function getSite()
	{
		return site;
	}

	/* captchaGenURL
	* Sets the default REST end point for captcha-gen to the production
	* URL, but can be set client-side once config file is loaded...
	* --------------------------------------------------------------------------- */
	var captchaGenURL = 'https://forms.rangeralpha.com/captcha/';

	function setCaptchaGenURL(strNewCaptchaGenURL){
		captchaGenURL = strNewCaptchaGenURL;
	}



	/* newCaptchaImage
	* Pass in the image object and the api will return a new captcha image and
	* replace the objects image source.
	* 
	* Pass in the the hidden form field object for the captcha's file name, and 
	* the api will set it's value to the captcha's file name. Captcha's file name
	* should be submitted with the form data to validate its captcha code.
	* 
	* @param: Image object
	* @param: Hidden field object
	* 
	* @returns: Sets captcha image source
	* @returns: Sets captcha file name form field
	* 
	* example: getCaptchaImage(this) or getCaptchaImage( $('#captcha') )
	* 
	* --------------------------------------------------------------------------- */
	function newCaptchaImage(imageObj, fileNameObj)
	{
		var captchaXHR = $.ajax({
			type     : 'POST',
			url      : captchaGenURL,
			dataType : 'json',
			data     : {
				'noise' : '2',           // number of lines in image
				//'size'  : '8',           // number of characters in captcha (default 5)
				//'bgcolor' : '#000000', // background color (none for transparent)
				// 'color' : '#ffffff'   // text color (none for black)
			}
		})
			.done(function(data)
			{
				imageObj.attr('src', data.url); // Captcha Image
				fileNameObj.val(data.file); // Form field
			})
			.fail(function(data){
				console.log('Captcha FAIL ' + data.disposition);
			});
	}



	/* formPostURL
	* Sets the default end point for api form posts. Defaults to the production
	* URL but can be set client-side using setter...
	* --------------------------------------------------------------------------- */
	var formPostURL = 'https://forms.rangeralpha.com/open/formsubmission';

	function setFormPostURL(strNewFormPostURL)
	{
		formPostURL = strNewFormPostURL;
	}



	/* JSONform
	* Convert webform fields to JSON
	* @param: webform object
	* @returns: JSON key/value pairs of form fields and data
	* --------------------------------------------------------------------------- */
	function JSONform(webFormObj)
	{
		let jsonFormData  = {};
		let arWebFormData = webFormObj.serializeArray(); // array of form data
		
		// Loops through each field in a webform, converts each form input 
		// name/value to a JSON object key/value pair. This way, we can add 
		// new form fields to forms with no additional coding
		arWebFormData.forEach(input => 
		{
			jsonFormData[input.name] = input.value;
		});

		return jsonFormData;
	}



	/* formPost
	* Post JSON form data to the api and return a XHR object for
	* client-side form handling / error & success messages etc.
	* @param: JSON webform data
	* @returns: XHR Object
	* --------------------------------------------------------------------------- */
	function formPost(formDataJSON)
	{
		var formXHR = $.ajax(
			{
				type     : 'POST',
				url      : formPostURL,
				contentType : 'application/json',
				data     : JSON.stringify( {
					'site'     : site,          // 'site' is a string db field
					'formData' : formDataJSON   // 'formdata' is a json db field
				} )
			});

		return formXHR;

	// end postForm()
	}



	/**
	 * ------------------------------------------------------------------------
	 * Methods to ship back to the module / Make Public
	 * ------------------------------------------------------------------------
	 */

	return{
		init              : init,
		setSite           : setSite,
		getSite           : getSite,
		setCaptchaGenURL  : setCaptchaGenURL,
		newCaptchaImage   : newCaptchaImage,
		setFormPostURL    : setFormPostURL,
		JSONform 		  : JSONform,
		formPost 		  : formPost
	};  

// end module
})();

/**
 * ------------------------------------------------------------------------
 * EXPORT MODULE
 * ------------------------------------------------------------------------
 */

module.exports = {NewsomeAPI};
